<template>
  <section id="add-workShift">
    <div class="add-student-body px-2">
      <div
          class="w-100 py-2"
      >
        <b-card>
          <h2 class="brand-text text-primary text-main-header">
            {{ isUpdate? 'Cập nhật' : 'Thêm mới' }} Wifi
          </h2>
          <validation-observer  ref="wifiDTO">
            <b-row class="col-md-12 col-12 mt-5">
              <b-col class="col-md-6 col-12 mt-1">
                <div class="form-group">
                  <validation-provider
                      #default="{ errors }"
                      name="Tên Wifi"
                      rules="required"
                  >
                    <label
                        class="label-add-work-shift"
                        for="address"
                    >Tên Wifi<span class="text-danger"> (*)</span></label>
                    <input
                        id="address"
                        v-model="wifiDTO.wifiName"
                        rules="required"
                        type="text"
                        class="form-control"
                        placeholder="Nhập tên wifi"
                    >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-col>
              <b-col class="col-md-6 col-12 mt-1">
                <div class="form-group">
                  <validation-provider
                      #default="{ errors }"
                      name="Wifi BSSID"
                      rules="required"
                  >
                    <label
                        class="label-add-work-shift"
                        for="address"
                    >
                      Wifi BSSID <span class="text-danger"> (*)</span></label>
                    <input
                        id="wifiBSSID"
                        v-model="wifiDTO.bssid"
                        rules="required"
                        type="text"
                        class="form-control"
                        placeholder="Nhập wifi BSSID"
                    >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-col>
            </b-row>
            <b-row class="col-md-12 col-12 mt-1">
              <b-col class="col-md-6 col-12">
                <div class="form-group">
                  <b-form-checkbox
                      v-model="wifiDTO.status"
                      class="label-add-work-shift"
                  >Trạng thái hoạt động
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>
            <div class="button-box-footer float-right mt-1">
              <div class="demo-inline-spacing">
                <b-button
                    v-if="!isUpdate"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="addWifi"
                >
                  Thêm mới
                </b-button>
                <b-button
                    v-if="isUpdate"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="addWifi"
                >
                  Cập nhật
                </b-button>
                <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    :to="{ name: 'manage-wifi'}"
                    variant="outline-danger"
                >
                  Quay lại
                </b-button>
              </div>
            </div>
          </validation-observer>
        </b-card>
      </div>
    </div>
  </section>
</template>

<script>
import {
  BCard, BRow, BCol, VBTooltip, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      required,
      dir: 'rtl',
      isUpdate: false,
      wifiDTO: {},
      idWifi: null,
    }
  },
  computed: {
  },
  created() {
    const parameters = this.$route.query
    if (parameters.id) {
      this.isUpdate = true
      this.idWifi = parameters.id
      this.getInfoWifi()
    }
  },
  methods: {
    getInfoWifi() {
      let id = this.$route.query.id
      if (!id) {
        return
      }
      this.$crm.post(`wifi/find-by-id/${this.idWifi}`).then(response => {
        if (response.data) {
          this.wifiDTO = response.data
          this.wifiDTO.status = true ? this.wifiDTO.status === 1 : this.wifiDTO.status === 0
        }
      })
    },
    addWifi() {
      this.$refs.wifiDTO.validate().then(success => {
        if (success) {
          this.wifiDTO.status = this.wifiDTO.status ? 1 : 0
          const url = !this.isUpdate ? 'wifi/create' : `wifi/update/${this.idWifi}`
          this.$crm.post(url, this.wifiDTO).then(res => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: this.isUpdate ? 'Cập nhật wifi chấm công thành công' : 'Tạo wifi chấm công thành công',
              },
            })
            this.$router.push({
              name: 'manage-wifi',
            })
          })
        }
      })
    },
    confirmDeleteRecord(index) {
      this.selectedWifiId = index
      this.$swal({
        title: 'Xác nhận xóa',
        text: 'Bạn có chắc là muốn xóa ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteRecord()
        }
      })
    },
    deleteRecord() {
      this.wifiDTO.splice(this.selectedWifiId, 1)
    },
  },
}
</script>

<style lang="scss">
@import '../../@core/scss/vue/libs/vue-select';
@import '../../@core/scss/custom/manager-workShift';
@import "~ag-grid-community/dist/styles/ag-grid.scss";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
